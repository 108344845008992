import to from 'await-to-js';
import { Job, VehicleType } from "@wearewarp/types/data-model";
import { Const as WarpConst } from "@wearewarp/universal-libs";
import StopEntity from "./StopEntity";
import { DispatchService } from "../dispatchService";
import { getInjector } from "@services/index";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import IssueEntity from './IssueEntity';
import { StringULID } from '@wearewarp/types';
import {Utils} from "@services/utils";
import { ResponseAdminStopInfoForJob } from '@wearewarp/types/rest-api/admin'; 

export default class RouteEntity {
  private data: Job
  private stops: StopEntity[] = [];
  private issues: IssueEntity[] = [];
  private dispatchService: DispatchService|undefined;

  constructor() {
    const injector = getInjector();
    this.dispatchService = injector.get(DispatchService);
  }

  public static async withJobId(jobId: string, ops?: {showErr?: (e) => void}): Promise<RouteEntity | undefined> {
    let url = Const.APIV2(`${Const.APIURI_JOBS}/${jobId}`);
    const [err, resp] = await to(ApiService.instance.GET(url).toPromise());
    if (err) {
      ops?.showErr?.(err);
    }
    let job = resp?.data;
    return job ? new RouteEntity().init(job) : undefined;
  }

  public async init(data: Job) {
    this.data = data;
    await this.initStops();
    await this.initIssues();
    return this;
  }

  public setDispatchService(dispatchService) {
    if (dispatchService) {
      this.dispatchService = dispatchService;
    }
    return this;
  }

  private async initStops() {
    if (!this.data.stops) {
      console.error(`Stops Data not found!`);
      return;
    }
    this.stops = await Promise.all(this.data.stops.map(async (stop) => await this.getStopInfo(stop)));
    this.stops = await Promise.all(this.data.stops.map(async (stop, index) => (await new StopEntity().setIndex(index).init(stop)).setDispatchService(this.dispatchService)))
  }

  private async initIssues() {
    if (!this.data.issues) {
      console.error(`Issues Data not found!`);
      return;
    }
    this.issues = await Promise.all(this.data.issues.map(async (issue) => (await new IssueEntity().init(issue))))
  }

  public getType() {
    return this.data.type ?? WarpConst.JobType.normal;
  }

  public isGhostLoad() {
    return this.data.type === WarpConst.JobType.ghost;
  }

  public isExternalRoute() {
    return this.data.type === WarpConst.JobType.external;
  }

  public isLinehaul() {
    return this.data.type === WarpConst.JobType.linehaul;
  }

  public getSource() {
    return this.data.source;
  }

  public isSourceMaketplace() {
    return this.data.source === WarpConst.JobSources.marketplace;
  }

  public getCode() {
    return this.data.code || ""
  }

  public getStatus() {
    return this.data.status || WarpConst.JobStatus.created
  }

  public getId() {
    return this.data.id
  }

  public getData() {
    return this.data;
  }

  public getShipments() {
    const shipmentIds = this.data.shipments.map(it => it.id);
    return shipmentIds.map(shipmentId => this.dispatchService.getShipmentById(shipmentId));
  }

  public isAssignedCarrier() {
    return this.data.assignedCarrier ? true : false
  }

  public getCarrierId() {
    return this.data.assignedCarrier?.carrierId;
  }

  public getDriverId() {
    return this.data.assignedDriver?.driverId;
  }

  public getAssignedSecondaryDrivers() {
    return this.data.assignedSecondaryDrivers ?? [];
  }

  public getSecondaryDriverId() {
    return this.getAssignedSecondaryDrivers()?.[0]?.driverId;
  }

  public getCarrier() {
    return this.data.carrier
  }

  public getCarrierCost() {
    //need define more type
    return this.data?.assignedCarrier?.cost
  }

  public getDriverDownTime() {
    return this.data?.driverDownTime
  }
  public getDriver() {
    return this.data.driver
  }
  public getPic() {
    return this.data?.pic;
  }
  public getTrailerNumber() {
    return this.data.trailerNumber
  }

  public getTruckNumber() {
    return this.data.truckNumber
  }

  public getReferenceNumber(): string {
    return this.getAssignedCarrier()?.referenceNumber ?? '';
  }

  public getCustomTracking() {
    return this.data.customTracking;
  }

  public isAssignedDriver() {
    return this.data.assignedDriver ? true : false
  }

  public getBolFileId() {
    return this.data.bolFileId;
  }
  public getAssignedCarrier() {
    return this.data.assignedCarrier
  }
  public getClients() {
    return this.data.clients
  }

  public getCarrierSalesRep() {
    return this.data.carrierSalesRep
  }

  public getTasks() {
    return this.data.taskIds.map(taskId => this.dispatchService.getTaskById(taskId))
  }

  public getTaskIds() {
    return this.data.taskIds ?? [];
  }

  public getTasksPickup() {
    const tasks = this.getTasks();
    return tasks.filter(it => it.getType() === WarpConst.TaskType.PICKUP);
  }

  public getTasksDropoff() {
    const tasks = this.getTasks();
    return tasks.filter(it => it.getType() === WarpConst.TaskType.DROPOFF);
  }

  public getStops() {
    return this.stops
  }

  public getIssues() {
    return this.issues;
  }

  public getShipmentType() {
    const shipments = this.getShipments();
    const shipment = shipments?.[0];
    return shipment?.getShipmentType()
  }

  public getShipmentModeId() {
    const shipments = this.getShipments();
    for (let shipment of shipments) {
      let shipmentModeId = shipment.getShipmentModeId();
      if (shipmentModeId) return shipmentModeId;
    }
    return null;
  }
  public getEquipmentId() {
    const shipments = this.getShipments();
    for (let shipment of shipments) {
      let shipmentModeId = shipment.getShipmentModeId();
      if (shipmentModeId) {
        return shipment.getEquipmentId();
      }
    }
    return null;
  }
  public getEtaForCurrentStop() {
    const stops = this.stops;
    let etaEnroutes: any[] = [];
    let etaPendings: any[] = [];

    for (let stop of stops) {
      const status = stop.getStatus();
      const etaTime = stop.getETA();
      const timezone = stop.getTimezone();
      if (status === WarpConst.TaskStatus.enroute && etaTime) etaEnroutes.push({ etaTime, timezone });
      if (status === WarpConst.TaskStatus.created && etaTime) etaPendings.push({ etaTime, timezone });
    }

    if (etaEnroutes.length) return etaEnroutes[etaEnroutes.length - 1];
    return etaPendings[etaPendings.length - 1];
  }

  public getTotalDistance() {
    return this.data.totalDistance;
  }

  public getTotalTime() {
    return this.data.totalTime;
  }

  public getClassificationSettings() {
    return this.data.classificationSettings;
  }

  public toJSON() {
    return this.data
  }

  public getRequiredVehicle(): VehicleType {
    return this.data.requiredVehicle
  }

  public getBasePrice() {
    return this.data.cost?.finance
  }

  public getAdditionalCarrierCost() {
    return this.data.assignedCarrier?.additionalCosts || [];
  }

  public isFirstAssigned() {
    return this.data?.assignedCarrier?.isFirstAssigned;
  }
  
  getRating() {
    return this.data.assignedCarrier?.rating
  }

  getRatingCarriers() {
    return this.data.assignedCarrier?.ratingCarriers ?? [];
  }
  getReports() {
    return this.data.assignedCarrier?.reports ?? [];
  }

  public getTrackingTasks() {
    return this.data.trackingTasks || {};
  }
  
  static async fetchCarrier(carrierId: StringULID) {
    if (!carrierId) return;

    let url = Const.API_GET_CARRIER_DETAIL_FOR_JOB(carrierId);
    const [err, resp] = await to(ApiService.instance.GET(url).toPromise());
    if (err) {
      console.log("-------------- RouteEntity fetchCarrier error", err);
    }
    return resp?.data
  }

  async getStopInfo(stop) {
    if (!stop?.id) return;
    let stopId = stop.id;
    let jobId = this.data?.id;
    let url = Const.API_GET_STOP_DETAIL_FOR_JOB(stopId, jobId);
    const [err, resp] = await to(ApiService.instance.GET(url).toPromise());
    if (err) {
      console.log("-------------- RouteEntity getStopInfo error", err);
    }
    let responseStopInfo: ResponseAdminStopInfoForJob = resp?.data;
    if(Utils.isObjectNotEmpty(responseStopInfo?.stopInfo?.itemInfo)) {
      stop["items"] = { 
        ...responseStopInfo?.stopInfo, 
        userInfo: responseStopInfo?.userInfo, 
        driverInfo: responseStopInfo?.driverInfo
      };
    } 
    return stop;
  }
}